var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "pa-0" },
    [
      _c(
        "v-row",
        { attrs: { "no-gutters": "" } },
        [
          _c(
            "v-col",
            { attrs: { cols: "7" } },
            [
              _c(
                "v-card",
                {
                  staticClass: "app-card pt-2 pb-16 h-100",
                  attrs: { tile: "" }
                },
                [
                  _c(
                    "v-toolbar",
                    {
                      staticClass: "mb-3 justify-center",
                      attrs: { flat: "", dense: "" }
                    },
                    [
                      _c(
                        "v-toolbar-title",
                        {
                          staticClass:
                            "text-subtitle-1 font-weight-medium secondary--text ml-auto",
                          class: {
                            "mr-auto": !_vm.items.length
                          }
                        },
                        [_vm._v(" " + _vm._s(_vm.title) + " ")]
                      ),
                      _vm.items.length
                        ? _c(
                            "div",
                            {
                              staticClass: "ml-2 mr-auto",
                              staticStyle: { width: "180px" }
                            },
                            [
                              _c("v-select", {
                                attrs: {
                                  value: _vm.value,
                                  items: _vm.items,
                                  "item-text": "name",
                                  "item-value": "value",
                                  dense: "",
                                  outlined: "",
                                  "hide-details": ""
                                },
                                on: {
                                  input: function($event) {
                                    return _vm.change($event)
                                  }
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  ),
                  _c(
                    "v-card",
                    {
                      staticClass: "mx-auto",
                      attrs: { elevation: "10", width: "350" }
                    },
                    [_c("v-img", { attrs: { src: _vm.avatar } })],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c("v-col", { attrs: { cols: "5" } }, [_vm._t("default")], 2)
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }