<template>
  <v-dialog
    v-model="dialog"
    max-width="900px"
    scrollable
    @input="changeDialog($event)">
    <template v-slot:activator="{ on, attrs }">
      <div class="relative">
        <v-img
          :src="avatar"
          height="160"
          class="receipt-avatar"
          contain>
        </v-img>
        <span class="font-weight-medium">
          {{ $t('receipt.note.size.dropoff') }}
        </span>
        <div class="edit-template">
          <v-btn
            color="primary"
            rounded
            depressed
            v-bind="attrs"
            v-on="on">
            <v-icon small>
              mdi-cog
            </v-icon>
            <span> {{ $t('receipt.btn.setting') }} </span>
          </v-btn>
        </div>
      </div>
    </template>
    <card-dialog
      :title="$t('receipt.note.dropoffTitle')"
      :avatar="avatar">
      <v-card
        class="app-card pt-2 pb-16 h-100 card-form-dialog"
        tile>
        <v-toolbar
          class="mb-3"
          flat
          dense>
          <v-toolbar-title class="text-subtitle-1 secondary--text font-weight-medium">
            {{ $t('receipt.editTitle') }}
          </v-toolbar-title>
          <v-spacer />
          <v-btn
            icon
            small
            @click="closeDialog()">
            <v-icon small>
              mdi-close
            </v-icon>
          </v-btn>
        </v-toolbar>
        <v-divider />
        <v-container>
          <v-row>
            <v-col
              cols="12"
              class="pb-0">
              <div class="secondary--text mb-4">
                {{ $t('receipt.note.field.defaultdropoff.label') }}
              </div>
              <select-form
                v-model="form.shippopLabelSize"
                :items="shippopLabelSizeEnum.enumValues"
                item-text="description"
                item-value="name"
                hide-details
                dense
                outlined
                :state="state.shippopLabelSize"
                @input="submit('shippopLabelSize')" />
            </v-col>
            <v-col
              cols="12"
              class="pb-0">
              <toggle-form
                v-model="form.dropoffShowProductList"
                :state="state['dropoffShowProductList']"
                :text="$t('receipt.note.field.showdropoff.label')"
                @input="submit('dropoffShowProductList')" />
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </card-dialog>
  </v-dialog>
</template>

<script>
import { GET_SETTING_RECEIPT, GET_SHIPPOPLABELSIZE_ENUM, UPDATE_SETTING } from '@/resources/graphql'
import CardDialog from '@/views/Receipt/components/CardDialog.vue'

export default {
  apollo: {
    shippopLabelSizeEnum: () => ({
      query: GET_SHIPPOPLABELSIZE_ENUM,
      update: ({ __type }) => __type
    }),
    setting: () => ({
      fetchPolicy: 'no-cache',
      query: GET_SETTING_RECEIPT,
      result ({ data: { setting } }) {
        this.form.dropoffShowProductList = setting.dropoffShowProductList
        this.form.shippopLabelSize = setting.shippopLabelSize
      }
    })
  },
  components: {
    CardDialog
  },
  data () {
    return {
      dialog: false,
      state: {
        shippopLabelSize: 'ready',
        dropoffShowProductList: 'ready'
      },
      form: {
        shippopLabelSize: null,
        dropoffShowProductList: null
      },
      box: {
        a4: `${process.env.VUE_APP_ROUTE_PREFIX}imgs/box/dropoff/SHIPPOP-Label-_-size-a4.jpg`,
        a5: `${process.env.VUE_APP_ROUTE_PREFIX}imgs/box/dropoff/SHIPPOP-Label-_-size-a5.jpg`,
        a6: `${process.env.VUE_APP_ROUTE_PREFIX}imgs/box/dropoff/SHIPPOP-Label-_-size-a6.jpg`,
        receipt: `${process.env.VUE_APP_ROUTE_PREFIX}imgs/box/dropoff/SHIPPOP-Label-_-size-receipt.jpg`, // letter ภาษาต่างดาว
        paperang: `${process.env.VUE_APP_ROUTE_PREFIX}imgs/box/dropoff/SHIPPOP-Label-_-size-paperang.jpg`,
        letter: `${process.env.VUE_APP_ROUTE_PREFIX}imgs/box/dropoff/SHIPPOP-Label-_-size-letter.jpg`,
        letter4x6: `${process.env.VUE_APP_ROUTE_PREFIX}imgs/box/dropoff/SHIPPOP-Label-_-size-letter4x6.jpg`,
        sticker: `${process.env.VUE_APP_ROUTE_PREFIX}imgs/box/dropoff/SHIPPOP-Label-_-size-sticker.jpg`,
        sticker4x6: `${process.env.VUE_APP_ROUTE_PREFIX}imgs/box/dropoff/SHIPPOP-Label-_-size-sticker4x6-_1_.jpg`
      },
      shippopLabelSizeEnum: {
        enumValues: []
      }
    }
  },
  computed: {
    avatar () {
      const src = this.box[`${this.form.shippopLabelSize}`]
      return src
    }
  },
  methods: {
    changeDialog (dialog) {
      if (dialog) { this.$apollo.queries.setting.refetch() }
    },
    closeDialog () {
      this.dialog = false
    },
    async submit (field) {
      this.state[field] = 'loading'
      const res = await this.$apollo.mutate({
        mutation: UPDATE_SETTING,
        variables: {
          input: {
            [field]: this.form[field]
          }
        }
      }).catch(() => {
        this.state[field] = 'error'
      })
      if (res) {
        const { data: { updateSetting } } = res
        if (updateSetting.errors && Object.keys(updateSetting.errors).length) {
          this.state[field] = 'error'
        } else {
          this.state[field] = 'success'
        }
      }
    }
  }
}
</script>

<style scoped>

</style>

<style >
</style>
