<template>
  <v-dialog
    v-model="dialog"
    max-width="900px"
    scrollable
    @input="changeDialog($event)">
    <template v-slot:activator="{ on, attrs }">
      <div class="relative">
        <v-img
          :src="avatar"
          height="160"
          class="receipt-avatar"
          contain>
        </v-img>
        <span class="font-weight-medium">
          {{ $t('receipt.note.size.a4&a5') }}
        </span>
        <div class="edit-template">
          <v-btn
            color="primary"
            rounded
            depressed
            v-bind="attrs"
            v-on="on">
            <v-icon small>
              mdi-cog
            </v-icon>
            <span> {{ $t('receipt.btn.setting') }} </span>
          </v-btn>
        </div>
      </div>
    </template>
    <card-dialog
      :title="$t('receipt.note.a4Title')"
      :avatar="avatar">
      <v-card
        class="app-card pt-2 pb-16 h-100 card-form-dialog"
        tile>
        <v-toolbar
          class="mb-3"
          flat
          dense>
          <v-toolbar-title class="text-subtitle-1 secondary--text font-weight-medium">
            {{ $t('receipt.editTitle') }}
          </v-toolbar-title>
          <v-spacer />
          <v-btn
            icon
            small
            @click="closeDialog()">
            <v-icon small>
              mdi-close
            </v-icon>
          </v-btn>
        </v-toolbar>
        <v-divider />
        <v-container>
          <v-row>
            <v-col
              cols="12"
              class="pb-0">
              <toggle-form
                v-model="form.orderBarcode"
                :state="state['orderBarcode']"
                :text="$t('receipt.note.field.barcode.label')"
                @input="submit('orderBarcode')" />
            </v-col>
            <v-col
              cols="12"
              class="pb-0">
              <div class="secondary--text mb-4">
                {{ $t('receipt.note.field.fontSize.label') }}
              </div>
              <v-select
                v-model="form.invoiceFont"
                :items="invoiceFontEnum.enumValues"
                item-text="description"
                item-value="name"
                hide-details
                dense
                outlined />
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </card-dialog>
  </v-dialog>
</template>

<script>
import { GET_INVOICEFONT_ENUM, GET_SETTING_RECEIPT, UPDATE_SETTING } from '@/resources/graphql'
import CardDialog from '@/views/Receipt/components/CardDialog.vue'

export default {
  apollo: {
    invoiceFontEnum: () => ({
      query: GET_INVOICEFONT_ENUM,
      update: ({ __type }) => __type
    }),
    setting: () => ({
      fetchPolicy: 'no-cache',
      query: GET_SETTING_RECEIPT,
      result ({ data: { setting } }) {
        this.form.orderBarcode = setting.orderBarcode
        this.form.invoiceFont = setting.invoiceFont
      }
    })
  },
  components: {
    CardDialog
  },
  data () {
    return {
      dialog: false,
      state: {
        invoiceFont: 'ready',
        orderBarcode: 'ready'
      },
      form: {
        orderBarcode: null,
        invoiceFont: null
      },
      invoiceFontEnum: {
        enumValues: []
      },
      box: {
        'true-invoice_font_large': `${process.env.VUE_APP_ROUTE_PREFIX}imgs/box/a4/isBarcode-Bigfont.jpg`,
        'true-invoice_font_small': `${process.env.VUE_APP_ROUTE_PREFIX}imgs/box/a4/isBarcode-smallfont.jpg`,
        'false-invoice_font_large': `${process.env.VUE_APP_ROUTE_PREFIX}imgs/box/a4/isnotBarcode-Bigfont.jpg`,
        'false-invoice_font_small': `${process.env.VUE_APP_ROUTE_PREFIX}imgs/box/a4/isnotBarcode-smallfont.jpg`
      }
    }
  },
  computed: {
    avatar () {
      const src = this.box[`${this.form.orderBarcode}-${this.form.invoiceFont}`]
      return src
    }
  },
  methods: {
    changeDialog (dialog) {
      if (dialog) { this.$apollo.queries.setting.refetch() }
    },
    closeDialog () {
      this.dialog = false
    },
    async submit (field) {
      this.state[field] = 'loading'
      const res = await this.$apollo.mutate({
        mutation: UPDATE_SETTING,
        variables: {
          input: {
            [field]: this.form[field]
          }
        }
      }).catch(() => {
        this.state[field] = 'error'
      })
      if (res) {
        const { data: { updateSetting } } = res
        if (updateSetting.errors && Object.keys(updateSetting.errors).length) {
          this.state[field] = 'error'
        } else {
          this.state[field] = 'success'
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>

</style>

<style lang="scss">
</style>
