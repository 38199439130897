<template>
  <v-container class="pa-0">
    <v-row no-gutters>
      <v-col cols="7">
        <v-card
          class="app-card pt-2 pb-16 h-100"
          tile>
          <v-toolbar
            class="mb-3 justify-center"
            flat
            dense>
            <v-toolbar-title
              :class="{
                'mr-auto': !items.length
              }"
              class="text-subtitle-1 font-weight-medium secondary--text ml-auto">
              {{ title }}
            </v-toolbar-title>
            <div
              v-if="items.length"
              class="ml-2 mr-auto"
              style="width:180px">
              <v-select
                :value="value"
                :items="items"
                item-text="name"
                item-value="value"
                dense
                outlined
                hide-details
                @input="change($event)" />
            </div>
          </v-toolbar>
          <v-card
            class="mx-auto"
            elevation="10"
            width="350">
            <v-img
              :src="avatar"
            />
          </v-card>
        </v-card>
      </v-col>
      <v-col
        cols="5">
        <slot></slot>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    },
    avatar: {
      type: String,
      default: ''
    },
    items: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    change (value) {
      this.$emit('input', value)
    }
  }

}
</script>

<style>

</style>

<style scoped>

</style>
