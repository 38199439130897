var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { "max-width": "900px", scrollable: "" },
      on: {
        input: function($event) {
          return _vm.changeDialog($event)
        }
      },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function(ref) {
            var on = ref.on
            var attrs = ref.attrs
            return [
              _c(
                "div",
                { staticClass: "relative" },
                [
                  _c("v-img", {
                    staticClass: "receipt-avatar",
                    attrs: { src: _vm.avatar, height: "160", contain: "" }
                  }),
                  _c("span", { staticClass: "font-weight-medium" }, [
                    _vm._v(" " + _vm._s(_vm.$t("receipt.note.size.8cm")) + " ")
                  ]),
                  _c(
                    "div",
                    { staticClass: "edit-template" },
                    [
                      _c(
                        "v-btn",
                        _vm._g(
                          _vm._b(
                            {
                              attrs: {
                                color: "primary",
                                rounded: "",
                                depressed: ""
                              }
                            },
                            "v-btn",
                            attrs,
                            false
                          ),
                          on
                        ),
                        [
                          _c("v-icon", { attrs: { small: "" } }, [
                            _vm._v(" mdi-cog ")
                          ]),
                          _c("span", [
                            _vm._v(
                              " " + _vm._s(_vm.$t("receipt.btn.setting")) + " "
                            )
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ]
          }
        }
      ]),
      model: {
        value: _vm.dialog,
        callback: function($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog"
      }
    },
    [
      _c(
        "card-dialog",
        {
          attrs: {
            title: _vm.$t("receipt.note.cmTitle"),
            avatar: _vm.avatar,
            items: _vm.previewType
          },
          on: {
            input: function($event) {
              return _vm.submit("addressSize")
            }
          },
          model: {
            value: _vm.form.addressSize,
            callback: function($$v) {
              _vm.$set(_vm.form, "addressSize", $$v)
            },
            expression: "form.addressSize"
          }
        },
        [
          _c(
            "v-card",
            {
              staticClass: "app-card pt-2 pb-16 h-100 card-form-dialog",
              attrs: { tile: "" }
            },
            [
              _c(
                "v-toolbar",
                { staticClass: "mb-3", attrs: { flat: "", dense: "" } },
                [
                  _c(
                    "v-toolbar-title",
                    {
                      staticClass:
                        "text-subtitle-1 secondary--text font-weight-medium"
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("receipt.editTitle")) + " ")]
                  ),
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { icon: "", small: "" },
                      on: {
                        click: function($event) {
                          return _vm.closeDialog()
                        }
                      }
                    },
                    [
                      _c("v-icon", { attrs: { small: "" } }, [
                        _vm._v(" mdi-close ")
                      ])
                    ],
                    1
                  )
                ],
                1
              ),
              _c("v-divider"),
              _c(
                "v-container",
                [
                  _c(
                    "v-row",
                    [
                      _vm.form.addressSize === "long"
                        ? _c(
                            "v-col",
                            { attrs: { cols: "12" } },
                            [
                              _c("toggle-form", {
                                attrs: {
                                  text: _vm.$t("receipt.note.field.tag.label"),
                                  state: _vm.state["invoiceShowTag"]
                                },
                                on: {
                                  input: function($event) {
                                    return _vm.submit("invoiceShowTag")
                                  }
                                },
                                model: {
                                  value: _vm.form.invoiceShowTag,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "invoiceShowTag", $$v)
                                  },
                                  expression: "form.invoiceShowTag"
                                }
                              })
                            ],
                            1
                          )
                        : _c("v-col", { attrs: { cols: "12" } }, [
                            _c("div", { staticClass: "secondary--text" }, [
                              _vm._v(
                                " " + _vm._s(_vm.$t("receipt.note.empty")) + " "
                              )
                            ])
                          ])
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }