var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "app-receipt" } },
    [
      _c("header-label", {
        attrs: { label: _vm.$t("receipt.header"), "prepend-icon": "$g_receipt" }
      }),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { cols: "12", md: "6" } },
            [
              _c(
                "v-card",
                { staticClass: "app-card", attrs: { flat: "" } },
                [
                  _c(
                    "v-card-text",
                    [
                      _c("title-label", {
                        attrs: { label: _vm.$t("receipt.slip.title") }
                      }),
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "6" } },
                            [_c("receipt-update-a-4-a-5")],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "6" } },
                            [_c("receipt-update-8-cm")],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { cols: "12", md: "10" } },
            [
              _c(
                "v-card",
                { staticClass: "app-card", attrs: { flat: "" } },
                [
                  _c(
                    "v-card-text",
                    [
                      _c("title-label", {
                        attrs: { label: _vm.$t("receipt.note.title") }
                      }),
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "4" } },
                            [_c("box-cover-update-8-cm")],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "4" } },
                            [_c("box-cover-update-a-4-a-5")],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "4" } },
                            [_c("box-cover-update-dropoff")],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "4" } },
                            [_c("box-cover-update-ems-dropoff")],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "4" } },
                            [_c("box-cover-product-list")],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { cols: "12", md: "3" } },
            [
              _c(
                "v-card",
                { staticClass: "app-card", attrs: { bordered: "", flat: "" } },
                [
                  _c(
                    "v-card-text",
                    [
                      _c("title-label", {
                        attrs: { label: _vm.$t("receipt.tax.title") }
                      }),
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "12" } },
                            [_c("tax-invoice")],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }