import { render, staticRenderFns } from "./CardDialog.vue?vue&type=template&id=47f8f860&scoped=true&"
import script from "./CardDialog.vue?vue&type=script&lang=js&"
export * from "./CardDialog.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "47f8f860",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';
installComponents(component, {VCard,VCol,VContainer,VImg,VRow,VSelect,VToolbar,VToolbarTitle})


/* hot reload */
if (module.hot) {
  var api = require("/opt/build/repo/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('47f8f860')) {
      api.createRecord('47f8f860', component.options)
    } else {
      api.reload('47f8f860', component.options)
    }
    module.hot.accept("./CardDialog.vue?vue&type=template&id=47f8f860&scoped=true&", function () {
      api.rerender('47f8f860', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/Receipt/components/CardDialog.vue"
export default component.exports