<template>
  <div id="app-receipt">
    <header-label
      :label="$t('receipt.header')"
      prepend-icon="$g_receipt" />
    <v-row>
      <v-col
        cols="12"
        md="6">
        <v-card
          class="app-card"
          flat>
          <v-card-text>
            <title-label :label="$t('receipt.slip.title')" />
            <v-row>
              <v-col cols="6">
                <receipt-update-a-4-a-5 />
              </v-col>
              <v-col cols="6">
                <receipt-update-8-cm />
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="12"
        md="10">
        <v-card
          class="app-card"
          flat>
          <v-card-text>
            <title-label :label="$t('receipt.note.title')" />
            <v-row>
              <v-col cols="4">
                <box-cover-update-8-cm />
              </v-col>
              <v-col cols="4">
                <box-cover-update-a-4-a-5 />
              </v-col>
              <v-col cols="4">
                <box-cover-update-dropoff />
              </v-col>
              <v-col cols="4">
                <box-cover-update-ems-dropoff />
              </v-col>
              <v-col cols="4">
                <box-cover-product-list />
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="12"
        md="3">
        <v-card
          class="app-card"
          bordered
          flat>
          <v-card-text>
            <title-label :label="$t('receipt.tax.title')" />
            <v-row>
              <v-col cols="12">
                <tax-invoice />
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import ReceiptUpdateA4A5 from '@/views/Receipt/components/ReceiptUpdateA4A5.vue'
import ReceiptUpdate8Cm from '@/views/Receipt/components/ReceiptUpdate8Cm.vue'
import BoxCoverUpdate8Cm from '@/views/Receipt/components/BoxCoverUpdate8Cm.vue'
import BoxCoverUpdateA4A5 from '@/views/Receipt/components/BoxCoverUpdateA4A5.vue'
import BoxCoverUpdateDropoff from '@/views/Receipt/components/BoxCoverUpdateDropoff.vue'
import BoxCoverUpdateEmsDropoff from '@/views/Receipt/components/BoxCoverUpdateEmsDropoff.vue'
import BoxCoverProductList from '@/views/Receipt/components/BoxCoverProductList.vue'
import TaxInvoice from '@/views/Receipt/components/TaxInvoice.vue'

export default {
  name: 'Receipt',
  components: {
    ReceiptUpdateA4A5,
    ReceiptUpdate8Cm,
    BoxCoverUpdate8Cm,
    BoxCoverUpdateA4A5,
    BoxCoverUpdateDropoff,
    BoxCoverUpdateEmsDropoff,
    BoxCoverProductList,
    TaxInvoice
  }
}
</script>

<style>
  #app-receipt .edit-template {
    position: absolute;
    top: 10px;
    right: 10px;
  }
  #app-receipt .receipt-avatar {
    border: 1px solid #f1f2f5;
    margin: 0 0 12px !important;
  }
  #app-receipt .card-form-dialog  {
    background-color: #f3f3f3 !important;
  }
  #app-receipt .card-form-dialog .v-sheet {
    background-color: inherit !important;
  }
</style>

<style scoped>

</style>
