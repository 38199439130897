<template>
  <v-dialog
    v-model="dialog"
    max-width="900px"
    scrollable
    @input="changeDialog($event)">
    <template v-slot:activator="{ on, attrs }">
      <div class="relative">
        <v-img
          :src="avatar"
          height="160"
          class="receipt-avatar"
          contain>
        </v-img>
        <span class="font-weight-medium">
          {{ $t('receipt.slip.size.a4&a5') }}
        </span>
        <div class="edit-template">
          <v-btn
            color="primary"
            rounded
            depressed
            v-bind="attrs"
            v-on="on">
            <v-icon small>
              mdi-cog
            </v-icon>
            <span> {{ $t('receipt.btn.setting') }} </span>
          </v-btn>
        </div>
      </div>
    </template>
    <card-dialog
      :title="$t('receipt.slip.a4Title')"
      :avatar="avatar">
      <v-card
        class="app-card pt-2 pb-16 h-100 card-form-dialog"
        tile>
        <v-toolbar
          class="mb-3"
          flat
          dense>
          <v-toolbar-title class="text-subtitle-1 secondary--text font-weight-medium">
            {{ $t('receipt.editTitle') }}
          </v-toolbar-title>
          <v-spacer />
          <v-btn
            icon
            small
            @click="closeDialog()">
            <v-icon small>
              mdi-close
            </v-icon>
          </v-btn>
        </v-toolbar>
        <v-divider />
        <v-container>
          <v-row>
            <v-col
              cols="12"
              class="pb-0">
              <toggle-form
                v-model="form.invoiceShowTag"
                :state="state['invoiceShowTag']"
                :text="$t('receipt.slip.field.tag.label')"
                @input="submit('invoiceShowTag')" />
            </v-col>
            <v-col
              cols="12"
              class="pb-0">
              <toggle-form
                v-model="form.showAnnotation"
                :state="state['showAnnotation']"
                :text="$t('receipt.slip.field.note.label')"
                @input="submit('showAnnotation')" />
            </v-col>
            <v-col
              cols="12"
              class="pb-0">
              <toggle-form
                v-model="form.invoiceShowBarcode"
                :text="$t('receipt.slip.field.barcode.label')"
                :state="state['invoiceShowBarcode']"
                @input="submit('invoiceShowBarcode')" />
            </v-col>
            <v-col
              cols="12">
              <div class="secondary--text mb-3">
                {{ $t('receipt.slip.field.imageSize.label') }}
              </div>
              <v-select
                v-model="form.invoiceImageSize"
                :items="invoiceImageSizeEnum.enumValues"
                item-text="description"
                item-value="name"
                hide-details
                dense
                outlined
                @input="submit('invoiceImageSize')" />
            </v-col>
            <v-col
              cols="12">
              <div class="secondary--text mb-3">
                {{ $t('receipt.slip.field.normalInvoiceNumberOption.label') }}
              </div>
              <v-select
                v-model="form.normalInvoiceNumberOption"
                :items="normalInvoiceNumberOptionEnum.enumValues"
                item-text="description"
                item-value="name"
                hide-details
                dense
                outlined
                @input="submit('normalInvoiceNumberOption')" />
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </card-dialog>
  </v-dialog>
</template>

<script>
import { GET_NORMALINVOICENUMBEROPTION_ENUM, GET_INVOICEIMAGESIZE_ENUM, GET_SETTING_RECEIPT, UPDATE_SETTING } from '@/resources/graphql'
import CardDialog from '@/views/Receipt/components/CardDialog.vue'

export default {
  apollo: {
    invoiceImageSizeEnum: () => ({
    query: GET_INVOICEIMAGESIZE_ENUM,
      update: ({ __type }) => __type
    }),
    normalInvoiceNumberOptionEnum: () => ({
    query: GET_NORMALINVOICENUMBEROPTION_ENUM,
      update: ({ __type }) => __type
    }),
    setting: () => ({
      fetchPolicy: 'no-cache',
      query: GET_SETTING_RECEIPT,
      result ({ data: { setting } }) {
        this.form.invoiceShowTag = setting.invoiceShowTag
        this.form.showAnnotation = setting.showAnnotation
        this.form.invoiceShowBarcode = setting.invoiceShowBarcode
        this.form.invoiceImageSize = setting.invoiceImageSize
        this.form.normalInvoiceNumberOption = setting.normalInvoiceNumberOption
      }
    })
  },
  components: {
    CardDialog
  },
  data () {
    return {
      dialog: false,
      invoiceImageSizeEnum: {
        enumValues: []
      },
      normalInvoiceNumberOptionEnum: {
        enumValues: []
      },
      state: {
        invoiceShowTag: 'ready',
        showAnnotation: 'ready',
        invoiceShowBarcode: 'ready',
        invoiceImageSize: 'ready',
        normalInvoiceNumberOption: 'ready'
      },
      form: {
        invoiceShowTag: null,
        invoiceShowBarcode: null,
        showAnnotation: null,
        invoiceImageSize: null,
        normalInvoiceNumberOption: null
      },
      invoice: {
        'false-false-false-normal_invoice_image': `${process.env.VUE_APP_ROUTE_PREFIX}imgs/invoice/a4/isnotBarcode-isnotTag-isnotnote-isSmallpic.jpg`,
        'false-false-true-normal_invoice_image': `${process.env.VUE_APP_ROUTE_PREFIX}imgs/invoice/a4/isnotBarcode-isnotTag-isnote-isSmallpic.jpg`,
        'false-true-false-normal_invoice_image': `${process.env.VUE_APP_ROUTE_PREFIX}imgs/invoice/a4/isnotBarcode-isTag-isnotnote-isSmallpic.jpg`,
        'false-true-true-normal_invoice_image': `${process.env.VUE_APP_ROUTE_PREFIX}imgs/invoice/a4/isnotBarcode-isTag-isnote-isSmallpic.jpg`,
        'false-false-false-large_invoice_image': `${process.env.VUE_APP_ROUTE_PREFIX}imgs/invoice/a4/isnotBarcode-isnotTag-isnotnote-isBigpic.jpg`,
        'false-false-true-large_invoice_image': `${process.env.VUE_APP_ROUTE_PREFIX}imgs/invoice/a4/isnotBarcode-isnotTag-isnote-isBigpic.jpg`,
        'false-true-false-large_invoice_image': `${process.env.VUE_APP_ROUTE_PREFIX}imgs/invoice/a4/isnotBarcode-isTag-isnotnote-isBigpic.jpg`,
        'false-true-true-large_invoice_image': `${process.env.VUE_APP_ROUTE_PREFIX}imgs/invoice/a4/isnotBarcode-isTag-isnote-isBigpic.jpg`,
        'true-false-false-normal_invoice_image': `${process.env.VUE_APP_ROUTE_PREFIX}imgs/invoice/a4/isBarcode-isnotTag-isnotnote-isSmallpic.jpg`,
        'true-false-true-normal_invoice_image': `${process.env.VUE_APP_ROUTE_PREFIX}imgs/invoice/a4/isBarcode-isnotTag-isnote-isSmallpic.jpg`,
        'true-true-false-normal_invoice_image': `${process.env.VUE_APP_ROUTE_PREFIX}imgs/invoice/a4/isBarcode-isTag-isnotnote-isSmallpic.jpg`,
        'true-true-true-normal_invoice_image': `${process.env.VUE_APP_ROUTE_PREFIX}imgs/invoice/a4/isBarcode-isTag-isnote-isSmallpic.jpg`,
        'true-false-false-large_invoice_image': `${process.env.VUE_APP_ROUTE_PREFIX}imgs/invoice/a4/isBarcode-isnotTag-isnotnote-isBigpic.jpg`,
        'true-false-true-large_invoice_image': `${process.env.VUE_APP_ROUTE_PREFIX}imgs/invoice/a4/isBarcode-isnotTag-isnote-isBigpic.jpg`,
        'true-true-false-large_invoice_image': `${process.env.VUE_APP_ROUTE_PREFIX}imgs/invoice/a4/isBarcode-isTag-isnotnote-isBigpic.jpg`,
        'true-true-true-large_invoice_image': `${process.env.VUE_APP_ROUTE_PREFIX}imgs/invoice/a4/isBarcode-isTag-isnote-isBigpic.jpg`
      }
    }
  },
  computed: {
    avatar () {
      const key = `${this.form.invoiceShowBarcode}-${this.form.invoiceShowTag}-${this.form.showAnnotation}-${this.form.invoiceImageSize}`
      const src = this.invoice[key]
      return src
    }
  },
  methods: {
    changeDialog (dialog) {
      if (dialog) { this.$apollo.queries.setting.refetch() }
    },
    closeDialog () {
      this.dialog = false
    },
    async submit (field) {
      this.state[field] = 'loading'
      const res = await this.$apollo.mutate({
        mutation: UPDATE_SETTING,
        variables: {
          input: {
            [field]: this.form[field]
          }
        }
      }).catch(() => {
        this.state[field] = 'error'
      })
      if (res) {
        const { data: { updateSetting } } = res
        if (updateSetting.errors && Object.keys(updateSetting.errors).length) {
          this.state[field] = 'error'
        } else {
          this.state[field] = 'success'
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>

</style>

<style lang="scss">
</style>
